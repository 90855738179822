<template>
  <div class="manage-lot-types animatedBox">
    <div class="container fluid">
      <!-- ============================ Filer ============================ -->
      <filter-panel
        v-model="isFilterActive"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Purpose"
            name="purpose"
            type="text"
          >
          </fd-input>
          <fd-select
            v-model="filter['propertyType:id']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Property Type"
            :options="propertyTypeOptions"
          >
          </fd-select>
        </div>
      </filter-panel>
      <!-- ============================ Table ============================ -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="lotTypesTableColumns"
          :rows="lotTypesTableData"
          :totalRows="lotTypesTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
        >
          <div slot="emptyarea">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="toAddLotType">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button class="btn mr-1" @click="toEditLotType(props.row.id)">
                <i class="fas fa-pen"></i>
              </button>
              <!-- TODO: Wait for delete API -->
              <button class="btn danger" @click="deleteLotTypes(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  name: "manage-lot-types",
  mixins: [vueGoodTable],
  components: {},
  data() {
    return {
      propertyTypeOptions: [],

      lotTypesTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "180px"
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "Property Type",
          field: "propertyType.name"
        },
        {
          label: "Last Update",
          field: "updatedAt",
          formatFn: (val) => {
            return this.$moment(val).format("LLL");
          }
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      lotTypesTableData: [],
      lotTypesTablePagination: {},
      isFilterActive: false,
      filter: {
        "name[partial]": "",
        "propertyType:id": ""
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);
      await Promise.all([
        this.getAllLotTypes({ limit: this.tableParams.perPage }),
        this.getAllPropertyTypes()
      ]);
      this.$store.commit("setIsLoading", false);
    },
    // Table related methods
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllLotTypes();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllLotTypes();
    },
    toAddLotType() {
      this.$router.push({ name: "ManageLotTypesAdd" });
    },
    toEditLotType(id) {
      this.$router.push({ name: "ManageLotTypesEdit", params: { id: id } });
    },

    // ===================== Filter related methods =====================
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    async filterData() {
      this.$store.commit("setIsLoading", true);
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      await this.getAllLotTypes();
      this.$store.commit("setIsLoading", false);
    },
    async clearFilter() {
      this.$store.commit("setIsLoading", true);
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      await this.getAllLotTypes();
      this.$store.commit("setIsLoading", false);
    },

    // ============================= API Related ===============================
    async getAllLotTypes() {
      let filteredParam = this.$cleanQueryParam(this.filter);
      let data = await this.$store.dispatch("manageLotTypes/getAllLotTypes", {
        ...filteredParam,
        page: this.tableParams.page,
        limit: this.tableParams.perPage
      });

      this.lotTypesTableData = this._.cloneDeep(data.data);
      this.lotTypesTablePagination = this._.cloneDeep(data.meta.pagination);
    },
    async getAllPropertyTypes() {
      let data = await this.$store.dispatch(
        "managePropertyTypes/getAllPropertyTypes",
        {
          limit: 200
        }
      );

      this.propertyTypeOptions = this._.cloneDeep(data.data);
    },

    async deleteLotTypes(id) {
      let confirm = this.$confirm({
        type: "alert",
        message: "Are you sure you want to delete?"
      });
      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.$store.dispatch("manageLotTypes/deleteLotTypes", id);
          this.getAllLotTypes();
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Lot Type is deleted successfully."
          });
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: " An unexpected error occured. Please try again later."
          });
          throw error;
        }
      }
    }
  }
};
</script>

<style>
.manage-lot-types {
}
</style>
